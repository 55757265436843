/**
 * Algorithme
 * ==========
 *
 * 1. Clique sur le bouton "Ajouter un avis"
 *   1.1. Affichage d'une modale avec un formulaire (`form.html.twig`)
 *   1.2. Si on a le DOM de présent pour le rating, initialiser `Rating()` dessus
 * 2. Soumission du formulaire
 *   2.1. Si Google Captcha activé, alors bind un callback (ajax) dessus
 *   2.2. Si Google Captcha activé, alors exécuter le captcha
 *   2.3. Si non, exécuter directement le callback (Requête AJAX)
 * 3. Requête AJAX vers le serveur
 *   3.1. Si erreur, afficher un message d'erreur
 *   3.2. Si erreur, reset le Google Captcha
 *   3.3. Si pas d'erreur, afficher le message de succès
 */

import { componentSelector } from './selectors';
import { Testimonial } from './testimonial';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    document.querySelectorAll(componentSelector).forEach(($testimonial) => new Testimonial($testimonial));
  },
  false
);
