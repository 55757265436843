/**
 * @typedef {Object} TestimonialConfiguration
 * @property {String} modalId
 * @property {Object} urls
 * @property {String} urls.showForm
 * @property {Object} googleCaptcha
 * @property {String} googleCaptcha.id
 * @property {Boolean} googleCaptcha.display
 */

import axios from 'axios';
import $ from 'jquery';

import { Rating } from './rating';
import { btnAddSelector, btnSubmitTestimonialSelector, formTestimonialSelector, ratingSelector } from './selectors';

/**
 * @return {TestimonialConfiguration}
 */
const getConfig = ($el) => JSON.parse($el.dataset.configuration);

export class Testimonial {
  constructor($testimonial) {
    this.config = getConfig($testimonial);
    this.$testimonial = $testimonial;
    this.$btnAddTestimonial = $testimonial.querySelector(btnAddSelector);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Testimonial', {
        config: this.config,
        $testimonial: this.$testimonial,
        $btnAddTestimonial: this.$btnAddTestimonial,
      });
    }

    this.bindBtnAddTestimonial();
  }

  /**
   * @private
   */
  bindBtnAddTestimonial() {
    let formContent = null;

    this.$btnAddTestimonial.addEventListener(
      'click',
      () => {
        if (formContent) {
          this.showModal();
          return;
        }

        this.loadForm().then((html) => {
          formContent = html;
          this.updateModalContent(html);
          this.showModal();
        });
      },
      false
    );
  }

  /**
   * @private
   */
  loadForm() {
    const params = {};
    if (this.config.googleCaptcha.display) {
      params.googleCaptchaId = this.config.googleCaptcha.id;
    }

    return new Promise((resolve) =>
      axios.get(this.config.urls.showForm, { params }).then((response) => resolve(response.data))
    );
  }

  /**
   * @private
   */
  updateModalContent(html) {
    const $modal = document.querySelector(`#${this.config.modalId}`);
    $modal.querySelector('[data-modal-body]').innerHTML = html;

    const $rating = $modal.querySelector(ratingSelector);
    if ($rating) {
      // eslint-disable-next-line no-new
      new Rating($rating);
    }

    // Check if modal renders `form_success.html.twig` content...
    if ($modal.querySelector('[data-google-recaptcha]')) {
      this.initGoogleRecaptchas();
    }

    this.bindSubmitButton($modal);
  }

  /**
   * @private
   */
  showModal() {
    const $modal = document.querySelector(`#${this.config.modalId}`);

    $($modal).modal('show');
  }

  /**
   * @private
   * @param {HTMLElement} $modal
   */
  bindSubmitButton($modal) {
    const $btnSubmit = $modal.querySelector(btnSubmitTestimonialSelector);

    // Check if modal renders `form_success.html.twig` content...
    if (!$btnSubmit) {
      return;
    }

    $btnSubmit.addEventListener(
      'click',
      (e) => {
        e.preventDefault();

        if (this.config.googleCaptcha.display) {
          this.bindGoogleRecaptchaCallback(() => this.submitForm($modal));
          this.executeGoogleRecaptcha();
        } else {
          this.submitForm($modal);
        }
      },
      false
    );
  }

  /**
   * @private
   * @param {HTMLElement} $modal
   */
  submitForm($modal) {
    const $form = $modal.querySelector(formTestimonialSelector);
    const headers = { 'X-Requested-With': 'XMLHttpRequest' };
    const params = {};

    if (this.config.googleCaptcha.display) {
      params.googleCaptchaId = this.config.googleCaptcha.id;
    }

    const onSuccess = (response) => {
      if (this.config.googleCaptcha.display) {
        this.removeGoogleRecaptcha();
      }

      this.updateModalContent(response.data);
    };

    /**
     * @param {AxiosError} error
     */
    const onFatalError = (error) => {
      this.updateModalContent(global.axiosForHuman(error));
    };

    axios
      .post($form.getAttribute('action'), global.serializeForm($form), { headers, params })
      .then((response) => onSuccess(response))
      .catch((error) => onFatalError(error));
  }

  /**
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  initGoogleRecaptchas() {
    global.initGoogleRecaptchas();
  }

  /**
   * @private
   */
  removeGoogleRecaptcha() {
    global.removeGoogleRecaptcha(this.config.googleCaptcha.id);
  }

  /**
   * @private
   */
  bindGoogleRecaptchaCallback(cb) {
    global.bindGoogleRecaptchaCallback(this.config.googleCaptcha.id, () => cb());
  }

  /**
   * @private
   */
  executeGoogleRecaptcha() {
    global.executeGoogleRecaptcha(this.config.googleCaptcha.id);
  }
}
