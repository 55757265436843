/**
 * @typedef {Object} StarsConfiguration
 * @property {Number|String} [initialValue=0]
 * @property {String} fieldId
 * @property {String} filledClass
 */
import { ratingItemsSelector } from './selectors';

/**
 * @returns {StarsConfiguration}
 */
const getConfig = ($el) => JSON.parse($el.dataset.configuration);

export class Rating {
  constructor($rating) {
    this.config = getConfig($rating);
    this.$rating = $rating;
    this.$ratingItems = this.$rating.querySelectorAll(ratingItemsSelector);
    this.$field = this.$rating.querySelector(`#${this.config.fieldId}`);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Rating', {
        config: this.config,
        $rating: this.$rating,
        $ratingItems: this.$ratingItems,
        $field: this.$field,
      });
    }

    this.setValue(parseInt(this.config.initialValue, 10) || 0);
    this.draw();
    this.bindEvents();
  }

  setValue(value) {
    this.value = value;
    this.$field.setAttribute('value', value);
  }

  draw(value = this.value) {
    this.forEachItems(($item) => {
      if ($item.dataset.value > value) {
        $item.classList.remove(this.config.filledClass);
      } else {
        $item.classList.add(this.config.filledClass);
      }
    });
  }

  /**
   * @private
   */
  bindEvents() {
    this.forEachItems(($item) => {
      $item.addEventListener(
        'click',
        () => {
          this.setValue($item.dataset.value);
          this.draw();
        },
        false
      );

      $item.addEventListener('mouseover', () => {
        this.draw($item.dataset.value);
      });

      $item.addEventListener('mouseout', () => {
        this.draw();
      });
    });
  }

  /**
   * @private
   * @param {Function} cb
   */
  forEachItems(cb) {
    for (let i = 0, len = this.$ratingItems.length; i < len; i += 1) {
      cb(this.$ratingItems[i]);
    }
  }
}
